body,
html {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', DroidKufi, Arial, 'Noto Sans',
        sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior-x: none;
}

html,
body {
    width: 100vw;
    height: 100vh;
}

#root {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html body {
    direction: ltr;
}

/* ::-webkit-scrollbar {
    width: 8px;
    background-color: rgba(23, 51, 79, 0.04);
    border-radius: 4px;
}

::-webkit-scrollbar:hover {
    background-color: rgba(23, 51, 79, 0.08);
}
::-webkit-scrollbar-thumb:vertical {
    background-color: rgba(255, 255, 255, 0.87);
    border-radius: 4px;
}
::-webkit-scrollbar-thumb:vertical:hover {
    background-color: rgba(255, 255, 255, 0.57);
}
::-webkit-scrollbar-thumb:vertical:active {
    background-color: rgba(255, 255, 255, 0.37);
} */

@media (max-width: 959.95px) {
    ::-webkit-scrollbar {
        width: 0;
    }
}

#app {
    height: 100%;
    width: 100%;
}

.fade-enter {
    opacity: 0;
}
.fade-exit {
    opacity: 1;
}
.fade-enter-active {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0;
}
.fade-enter-active,
.fade-exit-active {
    transition: opacity 200ms;
}

tbody.MuiTableBody-root tr:nth-child(odd) {
    background-color: rgba(55, 64, 71, 0.04);
}
.rccs {
    margin: 0 !important;
    margin-right: 48px !important;
}
.rccs__card {
    width: 316px !important;
    height: 200px !important;
    margin-right: 24px !important;
}
.rccs__number {
    font-size: 21px !important;
}
.rccs__name {
    font-size: 18px !important;
}
.rccs__expiry__valid {
    font-size: 12px  !important;

}
.rccs__expiry__value {
    font-size: 18px  !important;
    
}
.rccs__cvc {
    font-size: 17px  !important;

}